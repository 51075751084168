
.banner-facturation {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
  }
  
  
  .facturation_table {
  border-collapse: collapse;
  border-spacing: 7px;
  width: 100%;
  max-height: 600px; /* Limiter la hauteur du tableau à 600px */
  overflow-y: auto; /* Ajouter une barre de défilement vertical si nécessaire */
  display: block;
  /* padding-top: 10px; */
  }

  .table-container {
    overflow-x: auto;
    margin-bottom: 10px;
  }
  
  .tbody-container {
    overflow-y: auto;
    max-height: 600px; /* Ajustez selon les besoins */
  }
   
  
  
    .btn-declarer {
      /* margin-top: 20px;
      margin-left: 27px; */
      border: none; 
      background-color:#E6EFFC;
      color: #0052CC;
      padding: 7px 29px; 
      cursor: pointer; 
      font-size: 14PX;
      font-family: 'apple-system', sans-serif;
      font-weight: 500;
      z-index: 0;
    }
    .edit-btn, .delete-btn {
      border: none; 
      background-color: transparent;
    }
   
  /* css modal */
    /* .cancel-button {
      width: 100%;
      padding: 10px 20px;
      margin-top: 15px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      padding-left: 90px;
      padding-right:90px;
    }
    .cancel-button {
      font-weight: 600;
      color: #545050;
      background-color: white;
      border: solid 1px ;
    } */

    :root {
      --statut-a-revoir-text: #0747A6;
      --statut-a-revoir-background: #6b9ce4;
    
      --statut-en-revue-text: #0052CC;
      --statut-en-revue-background: #DEEBFF;
    
      --statut-termine-text: #f3780c;
      --statut-termine-background: #E3FCEF;
    }
    
    .custom-badge {
      font-size: 13px; /* Ajuster selon les besoins */
      padding:5px 15px; /* Ajuster selon les besoins */
    }
  
  /* Add more styles for hover effects, focus states, and responsiveness */
  .search-container-facturation {
 
    padding-bottom: 10px;
 
   }
   .search-input-facturation{
    width: 300px;
    padding-bottom: 5px;
    padding-top: 2px;
    border: 1.5px solid #a5a4a4;
   }


   .tabsFacturation {
    display: flex;
    align-items: center;
    /* margin-bottom: 20px; */
   
  }
  /* Facturation.css */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.label-commentaire {
  color: #1d1b1bec;
    font-weight: 400;
    display: block;
    margin-bottom: 2px;
    font-size: 13px;
}
.textarea-commentaire {
  width: 100%;
  height: 80px;
  font-size: 13px;
  font-family: "Maven Pro";
}