.container-statistique-envoimail {
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        width: 100%;
        height: 100% ;
        padding: 20px;
        border: 2px solid #ccc; /* Bordure grise claire */
        border-radius: 10px; /* Bords arrondis */
        margin: 20px;
        background-color: #fff; /* Fond blanc */
      }

