.modal{
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
    z-index: 1000; /* Assurez-vous que le modal est au-dessus des autres éléments */
  }
  
  .modal-content {
    max-width: 600px;
    max-height: 90vh;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 80%; /* Adjust width as necessary */
    overflow-y: auto;
    z-index: 55;
    margin: O;
  }
  .modal-body {
    height: 100%;
    padding: 0px;

  }
  
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #42526E;
      margin: 0;
      padding: 0;
     
  }
  .title {
    font-size: 20px;
    font-weight: 700;
  }
  
  .modal-title {
    margin: 0;
    color: #333;
  }
  
  .close-button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #AAA;
    font-size: 1.5rem;
  }
  
 
  @media (min-width: 768px) {
    /* Styles spécifiques pour les écrans de taille moyenne (tablets, etc.) */
   .modal-content {
      width: 60%;
    }
  
  }
  
  @media (min-width: 992px) {

   .modal-content {
      width: 50%;
    }
  }