



.societe_gestionnaire {
  border-radius: 12px;
    box-sizing: border-box;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    max-width: 100%;
    padding: var(--ds-space-025, 2px) 0;
    text-transform: uppercase;
    vertical-align: baseline;
    padding: 3px;
    padding-right: 164px;
}


.companylist_table {
  border-collapse: collapse;
  border-spacing: 7px;
  width: 95%; 
  overflow-y: auto; 
  margin-left: auto;
  margin-right: auto;
  display: block;
  overflow: auto; 
   max-height: 700px;  
}

.entete-portefeuille {

  display: flex;
  justify-content: space-between;
  align-items: center;


}

 .btn-ajout-portefeuille {
  border: none;
  font-size: 14PX;
  font-family: 'apple-system', sans-serif;
  font-weight: 600;
  background-color:#E6EFFC;
  color: #0052CC;
  cursor: pointer;
  padding: 12px 16px;
  margin-bottom: 10px;
  margin-right: 50px;
}

  
 

   .search-container {
    padding-left: 25px;
  
 
   }
   .search-input{
    padding-bottom: 5px;
    padding-top: 2px;
    border: 1.5px solid #a5a4a4;
    width: 300px;
   }

.btn-switch {
  padding-top: 8px;
    padding-bottom: 8px;
  
}
.switch-title {
  padding-right: 30px;
  font-size: 13px;
  font-size: 12px;
  font-weight: 600;
}
.switch {
  position: relative;
  display: inline-block;
  width: 120px; /* Augmenter la largeur pour plus d'espace */
  height: 28px; /* Augmenter légèrement la hauteur */
  margin: 0;
  padding: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9d9d9d; /* Couleur de fond pour OFF */
  transition: 0.4s;
  border-radius: 30px; /* Bordures plus arrondies */
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px; /* Agrandir le cercle pour mieux s'adapter à la hauteur */
  width: 24px; /* Agrandir le cercle pour mieux s'adapter à la hauteur */
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4CAF50; /* Couleur de fond pour ON */
}

input:checked + .slider:before {
  transform: translateX(92px); /* Ajuster le déplacement pour la nouvelle largeur */
}

.slider:after {
  content: "Société inactive"; /* Texte par défaut pour l'état OFF */
  position: absolute;
  color: black;
  width: 100%;
  left: 29px; /* Laisser de l'espace pour le cercle */
  bottom: 5px; /* Ajuster verticalement pour centrer */
  font-size: 12px; /* Taille de la police */
  white-space: nowrap; /* Empêcher le texte de passer à la ligne */
  transition: 0.4s; /* Animation pour le changement de texte */
  color: white;
}

input:checked + .slider:after {
  content: "Société active"; /* Texte pour l'état ON */
  left: 4px; /* Aligner à gauche pour l'état ON */

  color: white;
  
}


  