.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  height: 100px;
}

.main-nav a {
  font-weight: bold;
  color: #2c3e50;
}

.main-nav a.router-link-exact-active {
  color: #42b983;
}

.fa-user-circle,
.fa-sign-out {
  margin-right: 5px;
  margin-left: 20px;
  color:#42526e;
}

.main-nav-item {
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
margin-right:10px ;
cursor: pointer;
}

.main-nav-item:hover {
  text-decoration: underline;
}

.main-nav-logo {
  display: flex;
  align-items: center;
}

.main-nav-logo-image {
  max-width: 100%;
  width: 200px;
}

.navbar_loginSuccess {
  display: flex;
  flex-direction: column;

  align-items: center;
}
