/* ToastifyCustom.css */
.myToastClassNamesuccés {
    background-color: #0b6d26 !important;
    color: white !important;
  }
  
  .myToastProgressClassName {
    background: white !important;
  }
  .myToastClassNameerreur {
    background-color: #c32222 !important;
    color: white !important;
  }