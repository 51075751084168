.chiffres-cles {
    display: flex;
    justify-content:center;
    flex-direction: column;
    padding: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 100%;
    height:320px ;
    gap: 100px;
  }
  .stat-container {
    padding: 5px;
    display: flex;
    justify-content: center;
    gap: 5px;
    height: 200px;
  }
  .stat-block {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    border-radius: 20px;
    text-align: center;
    width: 150px;
  
  }
  .stat-block span {
    font-family: "Crimson Text";
font-size: 18px;
font-style: normal;
font-weight: 400;
  } 
  .stat-block strong {
    /*color: #9F44E4;*/

text-align: center;
font-family: "Crimson Text";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 71.429% */
text-transform: capitalize;
  }
  
  .ca-total {
    background-color: #e0d4ff; /* Purple */
  }
  
  .factures-total {
    background-color: #ffcccb; /* Red */
  }
  
  .top-gestionnaire {
    background-color: #ffff99; /* Yellow */
  }
  .nbfacture {
    background-color:#FFE7DD ;
  }
  .text-nbfacture {
    color: #F2692A;
  }
  .text-ca-total {
    color: #9F44E4;
  }
  .text-top-gestionnaire {
    color: #FAC512;

  }