.sidebar {
  background-color: #fff;
  border-right: 1px solid #ddd;
  height: 100%;
  width:15%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.sidebar-header {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.sidebar-header img {
  height: 52px;
  margin-bottom: 50px;
}

.sidebar-header h2 {
  padding-bottom: 50px;
  margin: 0;
  color: #333;
}

.sidebar-header p {
  color: #666;
  font-size: 0.85rem;
} 
.sidebar-menu {
  flex-grow: 1;
}

.menu-section {

  font-size: 16px;
  font-weight: 500;
  color: #42526e;
  margin-top: 20px;
  margin-bottom: 0px;
}

.menu-item {
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  color: #333;
  text-decoration: none;
  padding: 10px 0;
}

.menu-item.active {
  background-color: #E6EFFC;
  color: #0052CC;
}

.menu-item:not(.active):hover {
  background-color: #E6EFFC;
  color: #0052CC;
}

.menu-item svg {
  margin-right: 10px;
}
