.parent-layout-statiquestatut {
    display: flex;
    width: 100%;
    padding:0;
  }
  .content-statiquespage {
    width: 100%;
  }
  .choisi-mois{
    margin-bottom: 20px;
  }
  .containair-Statistiqes {
    display: flex;
    flex-wrap: wrap; /* Permet aux éléments de passer à la ligne suivante */
    align-items: center;
    justify-content: flex-start; /* Alignement des éléments à gauche */
    padding: 10px; /* Ajoute un peu de padding à l'intérieur du conteneur pour éviter que les éléments touchent les bords */
  }
  
  .containair-Statistiqes > div {
    flex: 0 0 48%; /* Chaque div prendra exactement 30% de la largeur du conteneur sans grandir ou rétrécir */
    max-width: 48%; /* Maximum width of each child to 30% to match flex-basis */
    box-sizing: border-box; /* Include padding and border in the element's width */
    margin: 10px 1%; /* Ajoute un peu d'espace autour de chaque div */
    padding: 5px; /* Optionnel: ajoute un peu de padding à l'intérieur de chaque div */
  }
  
  .composant {
    width: 100%;
  }
  .soustitle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.49);
font-family: "Crimson Text";
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 20px;
text-transform: lowercase;

  }

  @media (max-width: 768px) {
    .containair-Statistiqes > div {
        flex: 0 0 100%; /* Chaque div prendra 100% de la largeur du conteneur sur les petits écrans */
        max-width: 100%;
    }
  }
  