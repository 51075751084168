

.title-statiquestatut {
    
    text-align: center;
    padding: 10px;
    background-color: #f8f9fa; /* Fond légèrement gris */
    border: 2px solid #ccc; /* Ligne de séparation */
    width: 100%;

    color: #000;

text-align: center;
font-family: "Crimson Text";
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 86.957% */
text-transform: lowercase;
  }
  .container-statistique-statut {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    width: 100%;
   
    padding: 20px;
    border: 2px solid #ccc; /* Bordure grise claire */
    border-radius: 10px; /* Bords arrondis */
    margin: 20px;
    background-color: #fff; /* Fond blanc */
  }
  
  /* Legend */
  .recharts-wrapper .my-custom-legend {
    margin-left: 30px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
  }
  
  /* Spécifier des styles pour les éléments de la légende */
  .my-custom-legend .recharts-legend-item {
    margin-bottom: 10px; /* Espace entre les éléments */
  }
  
  /* Style pour les textes à l'intérieur de la légende */
  .my-custom-legend .recharts-legend-item-text {
    font-weight: bold;
  }
  .total-facturations {
    display: flex;
    justify-content: center;
    align-items: center;
  }