
  
 .banner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

 }
 .filtres-gestionnaires {
  margin-left: 20px;
 width: 700px;
 }
 .btn-container {
  display: flex;
 }
  .btn-generer {
    position: relative ;
    margin-bottom: 8px;
    margin-left: 10px;
    font-size: 14PX;
    font-family: 'apple-system', sans-serif;
    font-weight: 600;
    border: none; 
    background-color: #091E420F rgba(9, 30, 66, 0.08); 
    color: #42526E; 
    padding: 8px 12px; 
    cursor: pointer; 
  }
 .btn-exporter {
  position: relative ;
  width: 170px;
    margin-bottom: 8px;
    margin-left: 10px;
    font-size: 14PX;
    font-family: 'apple-system', sans-serif;
    font-weight: 600;
    border: none; 
    background-color: rgba(9, 30, 66, 0.08);    
    color: #42526E; 
    padding: 8px 24px 8px 12px; 
    cursor: pointer; 
    margin-right: 20px;
 }
 .dropdown-toggle::after {
  margin-left: 80px;
 }

  .gestionfacturation_table {
    border-collapse: collapse;
    border-spacing: 7px;
    width: 100%;
    max-height: 600px; /* Limiter la hauteur du tableau à 600px */
    overflow-y: auto; /* Ajouter une barre de défilement vertical si nécessaire */
    display: block;
    /* padding-top: 10px; */
    }
   
   
  
  .gestionfacturation_table-container{
    width: 100%;

  }
  .tbody-container {
    max-height: 600px; /* Hauteur après laquelle le défilement est activé */
    overflow-y: auto; /* Active le défilement vertical */
    width: 100%;
  }


   .gestionfacturation_btn-container {
    display: flex;
   justify-content: center;
   gap: 5px;
   }
   .btn-telecharger:disabled {
    opacity: 0.3;
  }
  .btn-telecharger {
   
    border: none;
    background-color:  white;
    color: #42526e;
    padding: 12px 16px;
    cursor: pointer;
    border-radius: 8px;
  }
  select {
    border-radius: 12px;
    box-sizing: border-box;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    max-width: 100%;
    padding: var(--ds-space-025, 2px) 0;
    text-transform: uppercase;
    vertical-align: baseline;
    padding: 3px;
 
    
  }
   /* Définition des couleurs en variables CSS */
:root {
  --statut-a-revoir-text: white; /* Changer les couleurs selon le besoin */
  --statut-a-revoir-background: rgb(255 193 7);

  --statut-en-revue-text: white; /* Changer les couleurs selon le besoin */
  --statut-en-revue-background: rgb(13 110 253);

  --statut-termine-text:white; /* Changer les couleurs selon le besoin */
  --statut-termine-background: rgb(25 135 84);
}

/* Utilisez des classes pour les couleurs de fond et de texte */
.select-status.revue {
  color: var(--statut-en-revue-text);
  background-color: var(--statut-en-revue-background);
  border: 0px solid var(--statut-en-revue-text); 
  font-size: 11px;
  font-weight: 500;
}

.select-status.revoir {
  color: var(--statut-a-revoir-text);
  background-color: var(--statut-a-revoir-background);
  border: 0px solid var(--statut-a-revoir-text); 
  font-size: 11px;
  font-weight: 500;
}

.select-status.Terminé {
  color: var(--statut-termine-text);
  background-color: var(--statut-termine-background);
  border: 0px solid var(--statut-termine-text); 
  font-size: 11px;
  font-weight: 500;
}
.container-filtres-gestionnaires {
  display: flex;
  justify-content:space-between;
}
.search-container-gestionfac {
 padding-left: 20px;
  padding-bottom: 10px;

 }
 .imput-search-container{
  padding-bottom: 5px;
 }
 .search-input-gestionfac{
  width: 300px;
  padding-bottom: 5px;
  padding-top: 2px;
  border: 1.5px solid #a5a4a4;
 }
 .status-counts {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  background-color: #f5f5f5; /* Couleur d'arrière-plan, changez selon vos besoins */
}

.status-count {
  margin: 0 1rem;
}
.statut-badges {
  padding-bottom:10px;
  padding-top: 10px;
    align-items: center;
}

/* filtres par mois  */


.current-month-label {
  margin: 0 15px;
  font-size: 18px;
  font-weight: bold;
}


.tabs {
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
  margin-left: 20px;
}

.tab {
  font-size:14px ;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #FFFFFF;
  margin-right: 2px;
  user-select: none; /* empêche la sélection du texte sur double-clic */
}

.tab.selected {
  background-color: #E6EFFC;
  color: #0052CC;
  font-weight: 600;
  font-size:14px ;
  /* border: 1px solid #0052CC; */
  /* border-bottom: none; */
  position: relative;
  top: 1px;
}

.add-tab {
  padding: 6px;
  cursor: pointer;
  margin-left: auto; /* pousse le bouton d'ajout à la droite */
  user-select: none;
}

.add-tab:hover {
  background-color: #0bc7f6;
}

/* Assurez-vous que vos onglets ne dépassent pas du conteneur */
.tabs {
  /* overflow-x: auto; */
  white-space: nowrap;
}
.iconmail {
 
  border: none;
  background-color: white;
  color: #42526e;

}
.btn_envoimail{
  
    background-color: white;
    border: none;
    padding: 5px; /* ajustez le padding pour que le bouton ait une meilleure apparence */
    cursor: pointer; /* style du curseur lors du survol du bouton */
    display: inline-flex; /* aligner l'icône et le texte correctement */
    align-items: center; /* centrer verticalement l'icône dans le bouton */
    justify-content: center; /* centrer horizontalement l'icône dans le bouton */
  
}
.btn_envoimail:disabled .iconmail {
  opacity: 0.3; 
}
