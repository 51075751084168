

.parent-layout-usres {
  display: flex;
  width: 100%;
  padding:0;
}
.content-users {
  width: 100%;
}
.users_table {
  border-collapse: collapse;
  border-spacing: 7px;
  width: 95%; 
  overflow-y: auto; 
  margin-left: auto;
  margin-right: auto;
  /* display: block; */
  overflow: auto; 
   max-height: 700px;  
}



/* css modal */


.societes-scroll-container {
  max-height: 150px; /* Ajustez cette valeur en fonction de vos besoins */
  overflow-y: auto; /* Active le défilement vertical si le contenu dépasse 150px */
  border: 1px solid #ccc; /* Optionnel, ajoute une bordure pour mieux visualiser le conteneur */
  padding: 10px; /* Optionnel, ajoute un peu d'espace à l'intérieur du conteneur */
  margin-top: 10px; /* Optionnel, ajoute un peu d'espace au-dessus du conteneur */
  margin-bottom: 10px;
}
.filtre {
  display: flex;
  justify-content: flex-start;
  gap: 50px;
  margin-left: 25px;
  width: 100%;
}

