.custom-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 30px;
  }
  .container_btn {
    display: flex;
    justify-content: space-around;
  }
  .title_modal {
    display: flex;
    justify-content: center;
    font-family: 'apple-system', sans-serif;
    font-weight: 600;
    color: #42526E;
  }
  .btn_confirmModal {

border: none;
    font-size: 14PX;
    font-family: 'apple-system', sans-serif;
    font-weight: 600;
    background-color: #E6EFFC;
    color: #0052CC;
    cursor: pointer;
    padding: 12px 16px;
    margin-bottom: 10px;
    margin-right: 50px;
  }
  .qst {
    display: flex;
    justify-content: center;
  }