body {
  /* max-width: 1440px; */
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Maven Pro", "Playfair Display","Dancing Script","Crimson Text", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.parent-layout {
  display: flex;
  width: 100vw; /* Utilisez 100vw pour prendre toute la largeur de la fenêtre */
  margin: 0; /* Assurez-vous qu'il n'y a pas de marges */
}

.content {
  max-width: 100%; /* Remplir l'espace disponible horizontalement */
  overflow-x: auto;
  margin: 0;
}
h1 {
  text-align: center; 

  font-weight: 600;
  font-size: 50px;
  
  padding-bottom: 20px;
  color: #42526E;
}
.container {
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  max-width: 100%; /* Ajustez la largeur maximale de votre choix */
  margin: 0 auto;
  overflow: hidden; /* Masquer tout dépassement de contenu */
}

.table-container {
  border-radius: 10px; /* Coins arrondis */
  width: 100%;
  overflow-x: auto;
  
}
thead {
  position: sticky;
  top: 0; /* Fixe l'en-tête en haut de la zone d'affichage */
  background-color: #fff; /* Assurez-vous que l'en-tête reste visible */
  /* Assurez-vous que l'en-tête reste au-dessus du contenu défilant */
}
th,
td {
  /* border: 1px solid black;  */
  border-bottom: 1px solid rgb(224, 220, 220);
  border-top: 1px solid rgb(224, 220, 220);
  text-align: left;
  /* border-radius: 8px; */
  text-align: left;
}
th {
  padding: 8px; /* Applique le padding uniquement aux cellules de données */
}

th {
  color: #a5a4a4;
  font-size: 14px;
}
td {
  color: #575555ec;
  font-family: "Maven Pro" sans-serif;
  font-size: 13px;
  font-family: 500;
  height: 35px;
  padding-left: 8px;
  padding-right: 20px; /* Ajout de 20 pixels de padding à droite */
}

td:first-child,
th:first-child {
  border-left: 1px solid rgb(224, 220, 220);
}

td:last-child,
th:last-child {
  border-right: 1px solid rgb(224, 220, 220);
}


.input-group {
  margin-bottom: 10px; /* Spacing between input fields */
}
.input-group label {
  color: #1d1b1bec;
  font-weight: 400;
  display: block;
  margin-bottom: 2px;
  font-size: 13px;
}

.input-group input {
  width: 100%; /* Full width */
  /* padding: 4px; */
  /* margin: 6px 0; */
  display: inline-block;
  border: 1px solid #817a7a;
  border-radius: 4px;
  box-sizing: border-box;
  /* margin-right: 65px; */
  font-size: 13px;
  font-family: "Maven Pro";
}
.flex {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  width: 97%;
}
.title_tarif {
  font-size: 16px;
  font-weight:700;
  color: #545050;
  border-bottom: solid 1px;
  margin: 1px;
  text-align: left;
}
.submit-button {
  background-color: #0052CC;
  color: white;
  border: none;
}
.cancel-button {
  color: #545050;
  background-color: white;
  border: solid 1px;
 
}

.cancel-button,
.submit-button {
  font-weight: 600;
  width: 100%;
  padding: 10px 20px;

  border-radius: 4px;
  cursor: pointer;
  padding-left: 90px;
  padding-right: 90px;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centre horizontalement */
  justify-content: center; /* Centre verticalement */
  padding: 20px;
}
.table-header {
  background-color: #EFEFEF;
  /* autres styles... */
}
.table-row:nth-child(even) {
  background-color: #F8F8F8;
}
.form-field {
  margin-bottom: 0px; /* ajoute de l'espace entre les champs */
}
.btn-container {
  display: flex;
 }

.form-actions {
  display: flex;
  flex-direction: row;
   justify-content: center; 
  gap: 10px;
  /* width: 100%; */
  text-align: center; /* Aligns buttons to the right */
  padding-right: 18px;
}
.montant {
  margin-left: 8px;
  width: 100%;

}
.close-button {
  float: right;
  font-size: 1.5em;
}
.edit-btn , .delete-btn  {
  color: #42526e;
 }
 .edit-btn, .delete-btn {
  border: none; 
  background-color: transparent;
  /* background-color: #C8D4F3; */
  
}
.btn-ajout {
  margin-bottom:  10px;
  margin-left: 27px;
  border: none;
  font-size: 14PX;
    font-family: 'apple-system', sans-serif;
    font-weight: 600;
  background-color:#E6EFFC;
  color: #0052CC;
  padding: 12px 16px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .form-actions {
    flex-direction: row; /* Rétablir la disposition en ligne pour les boutons */
    justify-content: center; /* Centrer les boutons horizontalement */
  }

}

@media (min-width: 992px) {
  /* Styles spécifiques pour les écrans larges (desktops, etc.) */
  .modal-content {
    width: 50%;
  }
}
