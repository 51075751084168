
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color:white /* Couleur de fond légère */
}

.login-banner {
   /* flex: 1;  */
   /* background: url("../../assets/imagelogin.png"), lightgray 50% / cover no-repeat; */
   background-color: #E5E5E5; 
  background-size:auto;
  background-position:right;

}

.login-form-container {
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  height: 600px;
  padding: 2rem;
  /* background: #fff; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.title-login {
  font-size: 64px;
  color: #2B458F;
  margin-bottom: 1rem;
  text-align: center;
  padding-bottom: 50px;
}
.form_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  
}


.login-button {
  font-size: 22px;
  width: 100%;
  height: 50px;
  padding: 0.5rem;
  border: none;
  background-color:#EAF3FA; /* Couleur de votre choix */
  color: #F68247;
  border-radius: 4px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #003d82; /* Une teinte plus foncée pour le survol */
}

.error-message {
  color: red;
}